.landing-wrapper {
  h1 {
    color: #17253d;
    letter-spacing: -0.01rem;
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: 600;
    line-height: 1.35;
  }

  h2 {
    color: #17253d;
    letter-spacing: -0.01rem;
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 26px;
    font-weight: 600;
    line-height: 1.35;
  }

  h3 {
    color: #17253d;
    letter-spacing: -0.01rem;
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 21px;
    font-weight: 600;
    line-height: 1.4;
  }

  h4 {
    color: #17253d;
    letter-spacing: -0.01rem;
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 19px;
    font-weight: 600;
    line-height: 1.45;
  }

  h5 {
    color: #17253d;
    letter-spacing: -0.01rem;
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
  }

  h6 {
    color: #17253d;
    letter-spacing: -0.01rem;
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.55;
  }

  p {
    margin-bottom: 25px;
  }

  a {
    color: #314494;
    text-decoration: none;
    transition: color 0.2s;
  }

  a:hover {
    color: #314494;
    border-bottom-color: #ccf2df;
  }

  ul {
    margin-top: 0;
    margin-bottom: 25px;
  }

  ol {
    margin-top: 0;
    margin-bottom: 25px;
    padding-left: 40px;
  }

  li {
    color: #60697b;
  }

  img {
    max-width: 100%;
    display: inline-block;
  }

  blockquote {
    border-left: 1px #000;
    margin-bottom: 25px;
    padding: 0;
    font-size: 17px;
    line-height: 1.7;
  }

  figure {
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 0;
  }

  figcaption {
    color: #b8b9b9;
    margin-top: 15px;
    font-size: 16px;
  }

  .body {
    color: #60697b;
    font-family: Space Grotesk, sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.7;
  }

  .section.background-primary-soft {
    background-color: rgba(101, 104, 169, 0.2);
  }

  .container {
    z-index: 2;
    width: 100%;
    height: 100%;
    max-width: 1290px;
    min-height: 30px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    transition: background-color 0.2s, color 0.2s;
    position: relative;
  }

  .sg-section {
    width: 100%;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    border-bottom: 1px solid #ebedf3;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    padding-top: 150px;
    padding-bottom: 150px;
    display: grid;
  }

  .sg-block {
    margin-bottom: 40px;
    display: block;
  }

  .sg-button-block {
    margin-right: 60px;
    display: inline-block;
  }

  .sg-color-grid {
    width: 100%;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }

  .sg-color-block {
    height: 80px;
    margin-bottom: 10px;
    display: block;
  }

  .sg-margin-block {
    width: 100%;
    background-color: #f0f0f8;
    display: flex;
  }

  .utility-page-content {
    width: 300px;
    text-align: center;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    padding-top: 120px;
    padding-bottom: 120px;
    display: flex;
  }

  .utility-page-form {
    grid-row-gap: 30px;
    flex-direction: column;
    align-self: center;
    align-items: flex-start;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .margin-bottom-none {
    margin-bottom: 0;
  }

  .margin-bottom-5 {
    margin-bottom: 5px;
  }

  .margin-bottom-10 {
    margin-bottom: 10px;
  }

  .margin-bottom-15 {
    margin-bottom: 15px;
  }

  .margin-bottom-20 {
    margin-bottom: 20px;
    font-family: Gilroy Medium, sans-serif;
  }

  .margin-bottom-25 {
    margin-bottom: 25px;
  }

  .margin-bottom-30 {
    margin-bottom: 30px;
  }

  .margin-bottom-35 {
    direction: ltr;
    margin-bottom: 35px;
  }

  .margin-bottom-40 {
    margin-bottom: 40px;
  }

  .margin-bottom-45 {
    margin-bottom: 45px;
  }

  .margin-bottom-50 {
    margin-bottom: 50px;
  }

  .margin-bottom-55 {
    margin-bottom: 55px;
  }

  .margin-bottom-60 {
    margin-bottom: 60px;
  }

  .margin-bottom-65 {
    margin-bottom: 65px;
  }

  .margin-bottom-70 {
    margin-bottom: 70px;
  }

  .margin-bottom-75 {
    margin-bottom: 75px;
  }

  .margin-bottom-80 {
    margin-bottom: 80px;
  }

  .background-primary {
    background-color: #314494;
  }

  .body-color {
    color: #60697b;
  }

  .text-primary {
    color: #314494;
  }

  .text-secondary {
    color: #aab0bc;
  }

  .text-white,
  .link-white {
    color: #fff;
  }

  .text-emphasize {
    font-family: IBM Plex Serif, sans-serif;
    font-style: italic;
  }

  .text-gradient {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(100deg, #314494 20%, #3f78e0);
    -webkit-background-clip: text;
    background-clip: text;
  }

  .text-gradient.text-emphasize {
    background-image: linear-gradient(100deg, #314494 20%, #2d74da);
    padding-left: 2px;
    padding-right: 2px;
  }

  .text-gradient.text-emphasize.nonitalic {
    font-family: Gilroy Medium, sans-serif;
    font-style: normal;
  }

  .display-1 {
    letter-spacing: -0.01rem;
    flex: none;
    margin-bottom: 0;
    font-size: 56px;
    line-height: 1.15;
  }

  .display-1.margin-bottom-20 {
    margin-bottom: 20px;
  }

  .display-2 {
    letter-spacing: -0.01rem;
    margin-bottom: 15px;
    font-family: Gilroy Bold, sans-serif;
    font-size: 44px;
    line-height: 1.2;
  }

  .display-2.margin-bottom-none {
    margin-bottom: 0;
  }

  .display-2.margin-bottom-25 {
    margin-bottom: 25px;
  }

  .display-2.margin-bottom-30 {
    margin-bottom: 30px;
  }

  .display-3 {
    letter-spacing: -0.01rem;
    margin-bottom: 15px;
    font-size: 36px;
    line-height: 1.25;
  }

  .display-3.text-white {
    font-family: Gilroy Bold, sans-serif;
  }

  .paragraph-large {
    // margin-top: 10px;
    margin-bottom: 35px;
    font-size: 25px;
    line-height: 1.5;
  }

  .label {
    letter-spacing: 0.02rem;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.35;
  }

  .label.text-secondary {
    font-family: Gilroy Bold, sans-serif;
  }

  .unicon {
    font-family: Unicons, sans-serif;
    line-height: 1;
  }

  .material-icon {
    font-family: Material Icons, sans-serif;
    line-height: 1;
  }

  .button {
    color: #fff;
    text-align: right;
    object-fit: fill;
    background-color: #314494;
    border-radius: 8px;
    flex: none;
    align-self: flex-start;
    padding: 12px 24px;
    font-family: Gilroy Bold, sans-serif;
    font-size: 17px;
    font-weight: 600;
    line-height: 1.7;
    transition: all 0.2s ease-in-out;
  }

  .button:hover {
    color: #fff;
    border-color: #314494;
    transform: translate(0, -3px);
    box-shadow: 0 5px 15px rgba(30, 34, 40, 0.15);
  }

  .button.cc-small {
    background-color: #314494;
    padding: 10px 22px;
    font-family: Gilroy Bold, sans-serif;
  }

  .button.cc-small.cc-form {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .button.cc-small.cc-form:hover {
    transform: none;
  }

  .button.cc-large {
    padding: 16px 28px 15px;
    font-family: Gilroy Bold, sans-serif;
    font-size: 18px;
  }

  .button.subbutton {
    width: 100%;
    text-align: center;
  }

  .image-link {
    width: 100%;
    cursor: none;
    transition: color 0.2s;
  }

  .link-hover {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    transition: border-color 0.2s, color 0.2s;
  }

  .link-hover:hover {
    color: #314494;
    border-bottom-color: #314494;
  }

  .link-hover.link-white {
    font-family: Gilroy Medium, sans-serif;
  }

  .link-hover.link-white:hover {
    color: #fff;
    border-bottom-color: #fff;
  }

  .shadow {
    box-shadow: 0 5px 35px rgba(30, 34, 40, 0.07);
  }

  .card {
    background-color: #fff;
    border-radius: 8px;
    padding: 40px;
  }

  .card.shadow {
    box-shadow: 0 5px 35px rgba(30, 34, 40, 0.07);
  }

  .background-primary-pale {
    background-color: #e6e5f4;
  }

  .background-primary-soft {
    background-color: #f0f0f8;
  }

  .background-light {
    background-color: #fcfcfd;
  }

  .background-dark {
    background-color: #17253d;
  }

  .navbar-wrapper {
    justify-content: space-between;
    align-items: flex-end;
    display: flex;
  }

  .navbar {
    z-index: 1999;
    width: 100%;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .navbar-space {
    height: 10px;
  }

  .navbar-inner {
    z-index: 5;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    margin-left: auto;
    margin-right: auto;
  }

  .nav-menu-wrapper {
    width: 100%;
  }

  .nav-menu-flex {
    align-items: center;
    display: flex;
  }

  .nav-menu {
    width: 100%;
    grid-column-gap: 50px;
    grid-row-gap: 20px;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    padding-bottom: 0;
    padding-left: 0;
    display: flex;
  }

  .nav-link {
    color: #17253d;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
    padding: 25px 0;
    font-family: Gilroy Medium, sans-serif;
    font-weight: 600;
    transition: color 0.2s;
    display: flex;

    &.selected {
      text-decoration: underline;
      text-underline-offset: 5px;
    }
  }

  .nav-link.w--current {
    color: #314494;
  }

  .burger-line {
    width: 25px;
    height: 3px;
    background-color: #17253d;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .nav-menu-flex-mobile {
    display: flex;
  }

  .navbar-wrapper-mobile {
    justify-content: space-between;
    align-items: center;
    display: none;
  }

  .navbar-inner-mobile {
    background-color: rgba(0, 0, 0, 0);
    flex-direction: column;
    align-items: center;
    display: none;
    position: relative;
    top: 0%;
    bottom: auto;
    left: 0%;
    right: 0%;
  }

  .navbar-brand-mobile {
    display: none;
  }

  .nav-link-mobile {
    height: 143px;
    color: #303030;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    transition: color 0.2s;
    display: flex;
  }

  .nav-link-mobile:hover {
    color: #314494;
  }

  .nav-link-mobile:focus-visible {
    outline-offset: 0px;
    color: #0050bd;
    border-radius: 4px;
    outline: 2px solid #0050bd;
  }

  .nav-link-mobile[data-wf-focus-visible] {
    outline-offset: 0px;
    color: #0050bd;
    border-radius: 4px;
    outline: 2px solid #0050bd;
  }

  .navbar-button-wrapper {
    flex: none;
  }

  .hero-grid {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    padding-top: 160px;
    padding-bottom: 170px;
    font-family: Gilroy Medium, sans-serif;
    display: grid;
  }

  .hero {
    min-height: 60vh;
    align-items: center;
    display: flex;
    position: relative;
  }

  .hero-image-wrapper {
    width: 50%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    right: 0;
  }

  .hero-content {
    height: 100%;
    padding-right: 100px;
    position: relative;
  }

  .play {
    z-index: 3;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
  }

  .play-button {
    width: 75px;
    height: 75px;
    color: #fff;
    background-color: #314494;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    display: flex;
  }

  .play-button:hover {
    color: #fff;
  }

  .doodle-arrow {
    position: absolute;
    bottom: 3%;
    right: 7%;
  }

  .doodle-shine {
    position: absolute;
    top: -7%;
    left: -6%;
  }

  .reasons {
    padding-top: 140px;
    padding-bottom: 140px;
  }

  .tabs-menu {
    grid-column-gap: 30px;
    grid-row-gap: 20px;
    justify-content: space-between;
    display: flex;
  }

  .tab {
    background-color: rgba(0, 0, 0, 0);
  }

  .tab.card {
    grid-column-gap: 20px;
    flex-direction: row;
    flex: 1;
    padding: 30px;
    font-family: Gilroy Medium, sans-serif;
    transition: box-shadow 0.2s, background-color 0.2s, color 0.2s;
    display: flex;
  }

  .tab.card:hover {
    color: #17253d;
    background-color: #fff;
    box-shadow: 0 5px 35px rgba(30, 34, 40, 0.07);
  }

  .tab.card.w--current {
    background-color: rgba(0, 0, 0, 0);
    font-family: Gilroy Medium, sans-serif;
    box-shadow: 0 5px 35px rgba(30, 34, 40, 0.07);
  }

  .tab-pane-grid {
    grid-column-gap: 70px;
    grid-row-gap: 50px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    font-family: Gilroy Medium, sans-serif;
    display: grid;
  }

  .tabs-content {
    margin-top: 60px;
  }

  .step {
    font-size: 50px;
    font-weight: 600;
    line-height: 1;
  }

  .bullet-list {
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    flex-direction: column;
    display: flex;
  }

  .bullet-list-item {
    grid-column-gap: 10px;
    display: flex;
  }

  .bullet {
    width: 20px;
    height: 20px;
    color: #314494;
    background-color: #e6e5f4;
    border-radius: 50%;
    flex: none;
    justify-content: center;
    align-items: center;
    margin-top: 4px;
    font-size: 15px;
    line-height: 1;
    display: flex;
  }

  .rounded {
    width: 100%;
    border-radius: 8px;
  }

  .headline-reasons {
    max-width: 660px;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    text-align: center;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  .headline-customers {
    max-width: 750px;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    text-align: center;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  .testimonials {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    column-count: 3;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    align-items: start;
  }

  .testimonial {
    margin-bottom: 30px;
    display: inline-block;
  }

  .testimonial-card-footer {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    align-items: center;
    display: flex;
  }

  .block-quote-with-icon {
    position: relative;
  }

  .quote-icon {
    position: absolute;
    top: 0%;
    bottom: auto;
    left: -10px;
    right: auto;
  }

  .avatar {
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }

  .rating {
    color: #fcc032;
    letter-spacing: -3px;
    margin-bottom: 20px;
    margin-left: -3px;
    font-size: 25px;
    line-height: 1;
    display: flex;
  }

  .customers {
    width: 100%;
    flex-direction: column;
    padding-top: 140px;
    padding-bottom: 140px;
    display: inline-block;
  }

  .services-grid {
    width: 100%;
    grid-column-gap: 0px;
    grid-row-gap: 80px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    padding-top: 140px;
    padding-bottom: 140px;
    font-family: Gilroy Medium, sans-serif;
    display: grid;
    position: relative;
  }

  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 140px;
  }

  .card-inner {
    grid-column-gap: 30px;
    display: flex;
  }

  .service-icon {
    width: 80px;
    flex: none;
  }

  .facts {
    min-height: 60vh;
    align-items: center;
    display: flex;
    position: relative;
  }

  .facts-image {
    width: 50%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: auto;
  }

  .facts-grid {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    padding-top: 160px;
    padding-bottom: 160px;
    display: grid;
  }

  .facts-content {
    height: 100%;
    padding-left: 100px;
    position: relative;
  }

  .numbers-grid {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }

  .numbers-grid.cc-margin-top {
    margin-top: 20px;
  }

  .image-grid {
    width: 100%;
    grid-column-gap: 25px;
    grid-row-gap: 25px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }

  .solutions-grid {
    width: 100%;
    grid-column-gap: 70px;
    grid-row-gap: 50px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    align-items: center;
    padding-top: 140px;
    padding-bottom: 140px;
    display: grid;
  }

  .image-padding {
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
  }

  .features-grid {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    font-family: Gilroy Medium, sans-serif;
    display: grid;
  }

  .pricing {
    width: 100%;
    flex-direction: column;
    padding-top: 120px;
    padding-bottom: 100px;
    display: inline-block;
  }

  .headline-pricing {
    max-width: 850px;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    text-align: center;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    margin-bottom: 100px;
    margin-left: auto;
    margin-right: auto;
  }

  .prices-grid {
    width: 100%;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    align-content: start;
    align-items: center;
    margin-bottom: 100px;
    display: grid;
  }

  .bold-text {
    font-weight: 600;
  }

  .price-currency {
    padding-top: 8px;
    padding-right: 5px;
    font-size: 20px;
    font-weight: 400;
  }

  .pricing-card-inner {
    flex-direction: column;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
    font-family: Gilroy Medium, sans-serif;
  }

  .price-duration {
    align-self: flex-end;
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 400;
  }

  .pricing-card {
    padding-bottom: 50px;
    display: flex;
  }

  .pricing-card.card.shadow {
    height: 100%;
    min-height: 400px;
    padding-top: 50px;
    padding-bottom: 60px;
  }

  .pricing-card.card.shadow.cc-larger {
    padding-top: 70px;
    padding-bottom: 80px;
  }

  .faq-wrapper {
    grid-column-gap: 60px;
    grid-row-gap: 60px;
    column-count: 2;
    flex-direction: column;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    column-gap: 60px;
    display: block;
  }

  .faq-item {
    margin-bottom: 30px;
    display: inline-block;
  }

  .faq-icon {
    width: 42px;
    height: 42px;
    color: #fff;
    background-color: #314494;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    display: flex;
  }

  .faq {
    flex: 1;
  }

  .faq-item-inner {
    grid-column-gap: 30px;
    display: flex;
  }

  .footer {
    padding-top: 100px;
    padding-bottom: 70px;
  }

  .footer.background-dark {
    color: #fff;
    background-color: #1c1c1b;
  }

  .footer-grid {
    width: 100%;
    grid-column-gap: 50px;
    grid-row-gap: 50px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }

  .address {
    max-width: 90%;
  }

  .address.margin-bottom-20 {
    font-family: Gilroy Medium, sans-serif;
  }

  .unordered-list {
    padding-left: 0;
    list-style-type: none;
  }

  .hero-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .price {
    font-family: Gilroy Bold, sans-serif;
    font-size: 36px;
    font-weight: 500;
    line-height: 1;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: rgba(164, 174, 198, 0.2);
    margin-top: 50px;
    margin-bottom: 70px;
  }

  .input-group {
    display: flex;
  }

  .text-field {
    height: 48px;
    color: #fff;
    border: 1px solid rgba(52, 63, 82, 0.2);
    border-radius: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .text-field:focus {
    border-width: 1px;
    border-color: #314494;
  }

  .text-field::-ms-input-placeholder {
    color: #aab0bc;
  }

  .text-field::placeholder {
    color: #aab0bc;
  }

  .text-field-dark {
    height: 48.8px;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.03);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-right-width: 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .text-field-dark:focus {
    border-width: 1px;
    border-color: #314494;
  }

  .text-field-dark::-ms-input-placeholder {
    color: #aab0bc;
  }

  .text-field-dark::placeholder {
    color: #aab0bc;
  }

  .field-label {
    display: none;
  }

  .footer-cta {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .join {
    max-width: 600px;
  }

  .play-button-pulse {
    z-index: -1;
    width: 70px;
    height: 70px;
    background-color: rgba(96, 93, 186, 0.5);
    border-radius: 100%;
    position: absolute;
    top: 50%;
    bottom: 0%;
    left: 50%;
    right: 0%;
    transform: translate(0, -50%) translate(-50%);
  }

  .navbar-shadow {
    height: 100%;
    position: absolute;
    top: auto;
    bottom: 0%;
    left: 0%;
    right: 0%;
    box-shadow: 0 0 25px rgba(30, 34, 40, 0.06);
  }

  .social-links {
    grid-column-gap: 20px;
    margin-bottom: 0;
    padding-left: 0;
    display: flex;
  }

  .social-link-item {
    line-height: 1;
  }

  .social-link {
    color: #fff;
    font-size: 20px;
    transition: transform 0.2s;
    display: flex;
  }

  .social-link:hover {
    color: #fff;
    transform: translate(0, -3px);
  }

  .page-headline {
    width: 80%;
    padding-top: 100px;
  }

  .page-title {
    letter-spacing: -0.03em;
    flex: none;
    font-size: 200px;
    font-weight: 400;
    line-height: 208px;
  }

  .inner-wrapper {
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;
  }

  .wrapper-narrow {
    width: 65%;
  }

  .wrapper-narrow.cc-center {
    text-align: center;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .image-2 {
    margin-right: 20px;
  }

  .text-block {
    margin-bottom: 59px;
  }

  .text-block-2,
  .text-block-3,
  .text-block-4,
  .text-block-5 {
    font-family: Gilroy Medium, sans-serif;
  }

  .bold-text-7,
  .bold-text-8 {
    font-family: Gilroy Bold, sans-serif;
  }

  .text-block-6,
  .text-block-7 {
    font-family: Gilroy Medium, sans-serif;
  }

  .heading,
  .bold-text-9 {
    font-family: Gilroy Bold, sans-serif;
  }

  .text-block-8,
  .text-block-9,
  .text-block-10 {
    font-family: Gilroy Medium, sans-serif;
  }

  .heading-2 {
    font-family: Gilroy Bold, sans-serif;
  }

  @media screen and (min-width: 1280px) {
    .section.background-primary-soft {
      background-color: rgba(36, 78, 147, 0.26);
    }

    .container {
      max-width: 1350px;
    }

    .sg-section {
      grid-column-gap: 16px;
      grid-row-gap: 16px;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr 1fr;
      grid-auto-columns: 1fr;
    }

    .utility-page-content {
      text-align: center;
      margin-top: auto;
      margin-bottom: auto;
    }

    .button {
      border-radius: 50px;
    }

    .button.cc-small {
      background-color: #244e93;
      border-radius: 50px;
    }

    .button.cc-large {
      background-color: #244e93;
    }

    .button.footerbutton {
      color: #17253d;
      background-color: #fff;
      border-radius: 50px;
    }

    .hero-image-wrapper {
      -webkit-text-fill-color: inherit;
      background-color: #fff;
      background-clip: border-box;
    }

    .solutions-grid {
      align-items: center;
    }

    .features-grid {
      grid-column-gap: 30px;
      grid-row-gap: 30px;
    }

    .footer.background-dark {
      background-color: #17253d;
    }

    .footer-grid {
      grid-template-columns: 1fr 1fr 1fr;
    }

    .hero-image {
      opacity: 1;
      background-color: #000;
    }

    .page-headline {
      width: 75%;
    }

    .wrapper-narrow {
      width: 55%;
    }

    .wrapper-narrow.cc-center {
      width: 63%;
    }

    .image {
      border: 1px solid #244e93;
    }

    .image-2 {
      cursor: pointer;
      margin-bottom: 0;
      margin-right: 20px;
      padding-right: 0;
    }

    .div-block {
      border-radius: 50px;
    }

    .div-block-2 {
      width: 100%;
      height: 100%;
      background-color: rgba(36, 78, 147, 0.26);
      position: absolute;
    }

    .bold-text-2 {
      color: #643131;
    }

    .bold-text-3 {
      color: #ececec;
    }

    .bold-text-4,
    .bold-text-5,
    .bold-text-6 {
      color: #fff;
    }
  }

  @media screen and (max-width: 991px) {
    .sg-block {
      margin-bottom: 40px;
    }

    .display-1 {
      font-size: 45px;
      line-height: 52px;
    }

    .display-2 {
      font-size: 37px;
      line-height: 44px;
    }

    .display-3 {
      font-size: 34px;
      line-height: 44px;
    }

    .paragraph-large {
      margin-bottom: 30px;
    }

    .button {
      position: relative;
    }

    .navbar-space {
      display: none;
    }

    .navbar-inner {
      display: none;
      position: relative;
    }

    .nav-menu-wrapper {
      z-index: 999;
      width: 100%;
      height: auto;
      background-color: #fff;
      position: absolute;
    }

    .nav-menu-flex {
      flex-direction: column;
      padding-left: 30px;
      padding-right: 30px;
    }

    .nav-menu {
      grid-row-gap: 0px;
      flex-flow: column wrap;
      justify-content: space-around;
      align-items: flex-start;
      padding-left: 0;
      display: flex;
      align-items: center;

      li:nth-child(1) {
        border-top: 1px solid #fff;
      }
      li {
        display: flex;
        justify-content: center;
        border-bottom: 1px solid #fff;
        width: 100%;
        a:hover {
          color: #fff;
        }
      }
    }

    .nav-link {
      height: auto;
      padding: 6px 0;
    }

    .menu-button {
      margin-left: 10px;
      padding: 0;
    }

    .menu-button.w--open {
      color: #60697b;
      background-color: rgba(0, 0, 0, 0);
    }

    .nav-menu-flex-mobile {
      flex-direction: column;
      padding: 30px 40px;
    }

    .nav-menu-wrapper-mobile {
      background-color: rgba(101, 104, 169, 1);
      position: absolute;
      height: 100vh;
      width: 100vw;
      left: 0;
      right: 0;
      margin: 0;
      top: 70px;
    }

    .navbar-wrapper-mobile {
      display: flex;
    }

    .navbar-inner-mobile {
      align-items: center;
      display: flex;
    }

    .navbar-brand-mobile {
      padding-top: 24px;
      padding-bottom: 24px;
      display: flex;
    }

    .nav-link-mobile {
      height: auto;
      color: #fff;
      padding: 6px 0;
      font-size: 16px;
    }

    .nav-link-mobile.w--current {
      color: #314494;
    }

    .hero-grid {
      padding-top: 120px;
      padding-bottom: 120px;
      display: flex;
    }

    .hero {
      display: block;
    }

    .hero-image-wrapper {
      width: 100%;
      position: relative;
    }

    .hero-content {
      padding-right: 0;
    }

    .play {
      left: 50%;
    }

    .doodle-arrow {
      display: none;
    }

    .doodle-shine {
      top: -10%;
    }

    .reasons {
      padding-top: 120px;
      padding-bottom: 120px;
    }

    .tabs-menu {
      flex-direction: column;
    }

    .tab-pane-grid {
      flex-direction: column;
      display: flex;
    }

    .headline-customers {
      margin-top: 0;
    }

    .testimonials {
      column-count: 2;
    }

    .customers {
      padding-top: 120px;
      padding-bottom: 110px;
    }

    .services-grid {
      flex-direction: column;
      padding-top: 120px;
      padding-bottom: 120px;
      display: flex;
    }

    .sticky {
      position: relative;
      top: auto;
    }

    .facts {
      display: block;
    }

    .facts-image {
      width: 100%;
      position: relative;
    }

    .facts-grid {
      padding-top: 120px;
      padding-bottom: 120px;
      display: flex;
    }

    .facts-content {
      padding-left: 0;
    }

    .solutions-grid {
      grid-template-columns: 1fr;
      padding-top: 120px;
      padding-bottom: 120px;
    }

    .image-padding {
      width: 100%;
    }

    .features-grid {
      grid-column-gap: 50px;
    }

    .pricing {
      padding-top: 120px;
      padding-bottom: 120px;
    }

    .headline-pricing {
      margin-top: 0;
    }

    .prices-grid {
      grid-template-columns: 1fr 1fr;
      grid-auto-columns: 1fr;
      align-content: center;
      justify-content: center;
    }

    .pricing-card.card.shadow.cc-larger {
      padding-top: 50px;
      padding-bottom: 60px;
    }

    .faq-wrapper {
      column-count: auto;
    }

    .footer.background-dark {
      padding-top: 80px;
      padding-bottom: 80px;
    }

    .footer-grid {
      width: 100%;
      grid-template-rows: auto auto;
      grid-template-columns: 1fr 1fr 1fr;
    }

    .footer-cta {
      grid-row-gap: 10px;
      flex-direction: column;
      align-items: flex-start;
    }

    .join {
      max-width: none;
    }

    .page-title {
      font-size: 140px;
      line-height: 148px;
    }

    .wrapper-narrow {
      width: 100%;
    }
  }

  @media screen and (max-width: 450px) {
    .navbar-brand-mobile > img {
      display: none;
    }
  }

  @media screen and (max-width: 767px) {
    .sg-section {
      grid-row-gap: 60px;
      grid-template-columns: 1fr;
      padding-top: 120px;
      padding-bottom: 120px;
    }

    .sg-color-grid {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .sg-color-block {
      width: 25%;
    }

    .paragraph-large {
      font-size: 22px;
      line-height: 34px;
    }

    .nav-menu {
      flex-direction: column;
      padding-left: 0;
    }

    .nav-link {
      padding-top: 10px;
      padding-bottom: 10px;
      display: inline-block;
    }

    .navbar-brand-mobile {
      padding-left: 0;
    }

    .doodle-shine {
      top: -9%;
      left: -8%;
    }

    .testimonials {
      column-count: 1;
    }

    .numbers-grid.cc-margin-top {
      grid-template-rows: auto auto;
    }

    .features-grid {
      flex-direction: column;
      display: flex;
    }

    .prices-grid {
      grid-template-rows: auto auto;
    }

    .footer-grid {
      flex-direction: column;
      display: flex;
    }

    .page-title {
      font-size: 50px;
      line-height: 58px;
    }
  }

  @media screen and (max-width: 479px) {
    .container {
      padding-left: 20px;
      padding-right: 20px;
    }

    .sg-button-block {
      margin-bottom: 20px;
    }

    .sg-color-grid {
      grid-template-columns: 1fr 1fr;
    }

    .button {
      width: auto;
      font-size: 16px;
    }

    .nav-menu-wrapper {
      width: 80%;
    }

    .nav-menu {
      flex-direction: column;
    }

    .nav-menu-wrapper-mobile {
      width: auto;
      position: absolute;
      height: 100vh;
      width: 100vw;
      left: 0;
      right: 0;
      margin: 0;
      top: 70px;
    }

    .doodle-shine {
      display: none;
    }

    .tab.card {
      grid-row-gap: 20px;
      flex-direction: column;
    }

    .card-inner {
      grid-row-gap: 30px;
      flex-direction: column;
    }

    .image-grid {
      flex-direction: column;
      display: flex;
    }

    .image-padding {
      padding-left: 0;
      padding-right: 0;
    }

    .text-field,
    .text-field-dark {
      height: 47px;
    }
  }

  #w-node-dc678c3f-8d8f-00ba-7466-144a35cd5f25-9c4ebe05,
  #w-node-_33d5ff0a-a9ee-1551-3986-b35178efb1e7-9c4ebe05,
  #w-node-b791cf82-82b9-7055-7e04-4f63f43e2f91-9c4ebe05,
  #w-node-b8bc016a-487b-09be-d595-e5e9ca4a85d9-9c4ebe05,
  #w-node-b8bc016a-487b-09be-d595-e5e9ca4a85d7-9c4ebe05,
  #w-node-_5010c074-a178-9e7c-8e5c-b0a0587d1c4b-9c4ebe05,
  #w-node-_5010c074-a178-9e7c-8e5c-b0a0587d1c4d-9c4ebe05 {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }

  #w-node-_72b8af2d-5524-9ad8-95e6-936ff7033a26-9c4ebe05 {
    grid-area: span 1 / span 5 / span 1 / span 5;
    align-self: start;
  }

  #w-node-e936af14-d0d6-3191-8d17-d905eda72cd8-9c4ebe05 {
    grid-area: 1 / 7 / 2 / 13;
    align-self: center;
  }

  #w-node-_46491234-f3d0-02a7-628d-9a38c3c37b66-9c4ebe05 {
    order: -9999;
    grid-area: span 1 / span 1 / span 1 / span 1;
  }

  #w-node-_46491234-f3d0-02a7-628d-9a38c3c37b68-9c4ebe05 {
    grid-area: 1 / 1 / 2 / 13;
  }

  #w-node-_46491234-f3d0-02a7-628d-9a38c3c37b6a-9c4ebe05 {
    grid-area: 2 / 1 / 2 / 7;
  }

  #w-node-_46491234-f3d0-02a7-628d-9a38c3c37b6c-9c4ebe05 {
    grid-area: 2 / 7 / 2 / 13;
  }

  #w-node-_46491234-f3d0-02a7-628d-9a38c3c37b6e-9c4ebe05,
  #w-node-d0f6ad60-6b32-56f5-bb7b-d2285ef547cd-9c4ebe05,
  #w-node-d0f6ad60-6b32-56f5-bb7b-d2285ef547c5-9c4ebe05,
  #w-node-d0f6ad60-6b32-56f5-bb7b-d2285ef547bd-9c4ebe05,
  #w-node-d0f6ad60-6b32-56f5-bb7b-d2285ef547d5-9c4ebe05,
  #w-node-_11e2dc3d-d1e1-458c-44c2-5749cd8efbcf-cd8efba7,
  #w-node-_11e2dc3d-d1e1-458c-44c2-5749cd8efbd8-cd8efba7 {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }

  #w-node-_2dbc92e1-f640-1dc3-f27e-e52bbea09c40-9c4ebe07 {
    grid-area: 1 / 3 / 2 / 11;
    justify-self: center;
  }

  #w-node-b970029d-e571-0109-e799-435511aae61b-9c4ebe08 {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }

  #w-node-b983a49d-4767-6b80-3997-caa78bbd2f8b-9c4ebe08 {
    grid-area: span 1 / span 3 / span 1 / span 3;
  }

  #w-node-_83d6f05d-741e-b844-451e-a28484925089-9c4ebe09 {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }

  #w-node-da5bc5d1-5870-faa9-f42d-73310396d194-9c4ebe09,
  #w-node-_8402628b-b44e-9f04-36d7-49de7eaa169e-9c4ebe09,
  #w-node-_63d9265d-44e4-fe1b-fb89-961a3581021a-9c4ebe09,
  #w-node-cd193904-5227-c440-3b84-8eef6d854f4a-9c4ebe0a,
  #w-node-ad7761c5-0720-1086-f378-34e757b0cffd-9c4ebe0a {
    grid-area: span 1 / span 3 / span 1 / span 3;
  }

  #w-node-c81625a2-c909-6fcf-246f-1f1f19b12839-9c4ebe0a,
  #w-node-c81625a2-c909-6fcf-246f-1f1f19b1283a-9c4ebe0a,
  #w-node-ad7761c5-0720-1086-f378-34e757b0cfff-9c4ebe0a,
  #w-node-ad7761c5-0720-1086-f378-34e757b0d000-9c4ebe0a,
  #w-node-_7027d086-beb1-cddc-5cdb-f269ad9343e3-9c4ebe0a,
  #w-node-_7027d086-beb1-cddc-5cdb-f269ad9343e4-9c4ebe0a,
  #w-node-_6405011f-2a7e-8ce7-7169-6f6b1ee055c5-9c4ebe0a,
  #w-node-_6405011f-2a7e-8ce7-7169-6f6b1ee055c6-9c4ebe0a,
  #w-node-_42cacc95-a55c-b1f0-3043-33031bbb9305-9c4ebe0a,
  #w-node-_42cacc95-a55c-b1f0-3043-33031bbb9306-9c4ebe0a {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }

  #w-node-_9f7e7334-9ae7-5911-fafe-fba726522020-9c4ebe0a,
  #w-node-c2b652eb-a6a6-0a7e-9c10-9efbc862e0e4-9c4ebe0a,
  #w-node-_34dcb27b-e423-35af-8dd0-6545b5f0bb05-9c4ebe0a,
  #w-node-_32f9502b-72a1-33f0-a67d-4b6460ba94d7-9c4ebe0a {
    grid-area: span 1 / span 3 / span 1 / span 3;
  }

  @media screen and (min-width: 1280px) {
    #w-node-b983a49d-4767-6b80-3997-caa78bbd2f88-9c4ebe08 {
      grid-area: span 1 / span 1 / span 1 / span 1;
    }

    #w-node-b983a49d-4767-6b80-3997-caa78bbd2f8b-9c4ebe08 {
      grid-area: 1 / 2 / 2 / 4;
    }

    #w-node-da5bc5d1-5870-faa9-f42d-73310396d191-9c4ebe09 {
      grid-area: span 1 / span 1 / span 1 / span 1;
    }

    #w-node-da5bc5d1-5870-faa9-f42d-73310396d194-9c4ebe09 {
      grid-area: 1 / 2 / 2 / 4;
    }

    #w-node-_8402628b-b44e-9f04-36d7-49de7eaa169b-9c4ebe09 {
      grid-area: span 1 / span 1 / span 1 / span 1;
    }

    #w-node-_8402628b-b44e-9f04-36d7-49de7eaa169e-9c4ebe09 {
      grid-area: 1 / 2 / 2 / 4;
    }

    #w-node-_63d9265d-44e4-fe1b-fb89-961a35810217-9c4ebe09 {
      grid-area: span 1 / span 1 / span 1 / span 1;
    }

    #w-node-_63d9265d-44e4-fe1b-fb89-961a3581021a-9c4ebe09 {
      grid-area: 1 / 2 / 2 / 4;
    }

    #w-node-cd193904-5227-c440-3b84-8eef6d854f47-9c4ebe0a {
      grid-area: span 1 / span 1 / span 1 / span 1;
    }

    #w-node-cd193904-5227-c440-3b84-8eef6d854f4a-9c4ebe0a {
      grid-area: 1 / 2 / 2 / 4;
    }

    #w-node-ad7761c5-0720-1086-f378-34e757b0cffa-9c4ebe0a {
      grid-area: span 1 / span 1 / span 1 / span 1;
    }

    #w-node-ad7761c5-0720-1086-f378-34e757b0cffd-9c4ebe0a {
      grid-area: 1 / 2 / 2 / 4;
    }

    #w-node-_9f7e7334-9ae7-5911-fafe-fba72652201d-9c4ebe0a {
      grid-area: span 1 / span 1 / span 1 / span 1;
    }

    #w-node-_9f7e7334-9ae7-5911-fafe-fba726522020-9c4ebe0a {
      grid-area: 1 / 2 / 2 / 4;
    }

    #w-node-c2b652eb-a6a6-0a7e-9c10-9efbc862e0e1-9c4ebe0a {
      grid-area: span 1 / span 1 / span 1 / span 1;
    }

    #w-node-c2b652eb-a6a6-0a7e-9c10-9efbc862e0e4-9c4ebe0a {
      grid-area: 1 / 2 / 2 / 4;
    }

    #w-node-_34dcb27b-e423-35af-8dd0-6545b5f0bb02-9c4ebe0a {
      grid-area: span 1 / span 1 / span 1 / span 1;
    }

    #w-node-_34dcb27b-e423-35af-8dd0-6545b5f0bb05-9c4ebe0a {
      grid-area: 1 / 2 / 2 / 4;
    }

    #w-node-_32f9502b-72a1-33f0-a67d-4b6460ba94d4-9c4ebe0a {
      grid-area: span 1 / span 1 / span 1 / span 1;
    }

    #w-node-_32f9502b-72a1-33f0-a67d-4b6460ba94d7-9c4ebe0a {
      grid-area: 1 / 2 / 2 / 4;
    }
  }

  @media screen and (max-width: 991px) {
    #w-node-_46491234-f3d0-02a7-628d-9a38c3c37b66-9c4ebe05 {
      order: -9999;
    }
  }

  @media screen and (max-width: 767px) {
    #w-node-_53334cf9-df90-50d4-4a1f-ea55c60f3ce7-9c4ebe05,
    #w-node-e77134c4-000f-761b-6a4f-29a07b236df4-9c4ebe05,
    #w-node-_32055338-ab06-1c25-f0e4-bd41c682259c-9c4ebe05,
    #w-node-_5fed8f60-c41e-958b-95fd-777fa35117cc-9c4ebe05,
    #w-node-_42f2fc2c-4869-fde7-ee97-ac54a00d1e19-9c4ebe05 {
      grid-area: span 1 / span 2 / span 1 / span 2;
    }
  }
}
// 21-03-2024
.landing-wrapper {
  .faq-icon {
    background-image: url('./images/Logo_horizontal_white_small.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 90%;
    .unicon {
      display: none;
    }
  }
  .video_section {
    padding-bottom: 100px;
  }
}
